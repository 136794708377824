import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaFacebook } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import Header from "../header";
const Supports = () => {
    const [supportsdata, setSupportsdata] = useState({})

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        SupportsList()
    }, [])
    const SupportsList = async () => {
        let data = new FormData();
        try {
            const res = await axios.get("contact_us", data);
            console.log(res.data.data);
            setSupportsdata(res.data.data.contact);
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    return (
        <>
            <div>
                <ToastContainer 
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div>
                    <div class="container my-5">
                        <div class="card shadow">
                            <div class="card-body">
                                <h2 class="card-title text-center mb-4">Support</h2>
                                <div class="mb-3">
                                    <p class="mb-1"><strong>Email:</strong> <a href="" >{supportsdata.email}</a></p>
                                    <p class="mb-1"><strong>Mobile:</strong> <a href="">{supportsdata.mobile}</a></p>
                                    <p class="mb-1"><strong>WhatsApp:</strong> <a href="" target="_blank">{supportsdata.whatsapp_number}</a></p>
                                </div>
                                <div class="mb-4">
                                    <h3 class="h5">About Us</h3>
                                    <p className="text-bold">{supportsdata.aboutus}</p>
                                </div>
                                <div class="d-flex gap-2">
                                    <h3 class="h5 me-3">Follow Us</h3>
                                    <a href={supportsdata.facebook_link} target="_blank">
                                        <FaFacebook className="fs-2" />
                                    </a>
                                    <a href={supportsdata.instagram_link}  target="_blank">
                                        <FaSquareInstagram className="fs-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
export default Supports