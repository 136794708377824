// import { useEffect, useState } from "react";
// import { FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Button } from "@mui/material";
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import Header from "../components/header";
// import { ToastContainer, toast } from "react-toastify";
// import Signup from "../components/signup";
// import Otp from "../components/otp";
// import Login from "../components/login";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import axios from "axios";

// const RegisterDetail = () => {
//     const busIcon = process.env.PUBLIC_URL + 'assets/images/bus-icon.svg';
//     const [mobileNo, setMobileNo] = useState()
//     const [password, setPassword] = useState()
//     const [showPassword, setShowPassword] = useState(false);
//     const handleMouseDownPassword = (event) => event.preventDefault();
//     const [showPasswordIcon, setShowPasswordIcon] = useState(false)
//     const [errors, setErrors] = useState({});
//     const [registerData, setRegisterData] = useState([]);
//     const [userName, setUserName] = useState()
//     const [emailID, setEmailID] = useState()
//     const handleClickPassword = () => setShowPasswordIcon((show) => !show);
//     const history = useHistory()
//     const [otp, setOtp] = useState();
//     const [showOtpBox, setShowOtpBox] = useState(false)
//     const [registerBox, setRegisterBox] = useState(true)
//     const [timer, setTimer] = useState(30);
//     const [resendEnabled, setResendEnabled] = useState(false);

//     useEffect(() => {
//         if (timer > 0) {
//             const countdown = setInterval(() => {
//                 setTimer((prevTimer) => prevTimer - 1);
//             }, 1000);
//             return () => clearInterval(countdown);
//         } else {
//             setResendEnabled(true);
//         }
//     }, [timer]);

//     const handleOtp = () => {
//         const newErrors = {};
//         if (!otp) {
//             newErrors.otp = 'please enter Otp';
//             toast.error('Please enter Otp');
//         }
//         setErrors(newErrors);
//         const isValid = Object.keys(newErrors).length === 0;
//         if (isValid) {
//             console.log('add droppoint')
//             RegisterAPI();
//         }
//     };
//     const handleResendOtp = () => {
//         setTimer(30);
//         setResendEnabled(false);
//         // handleForgotAPI();
//         handelRegisterAPi()
//         // Add your resend OTP logic here
//     };

//     // const handleForgotAPI = async () => {
//     //     let data = new FormData();
//     //     data.append('number', mobileNo)
//     //     data.append('password', password)
//     //     try {
//     //         await axios.post("customer_forgot_password", data, {
//     //         }).then((res) => {
//     //             if (res.data.success == true) {
//     //                 setShowOtpBox(true)
//     //                 // setForgotBox(false)
//     //                 // setOtpAPI(res.data.data.otp)
//     //                 toast.success(res.data.message);
//     //             } else {
//     //                 toast.error(res.data.message || 'Invalid Message');
//     //             }
//     //         })
//     //     }
//     //     catch (res) {
//     //         toast.error(res.data.message);
//     //     }
//     // }

//     const RegisterAPI = () => {
//         history.push('/login')
//     }

//     const handleNext = () => {
//         const newErrors = {};
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (!userName) {
//             newErrors.userName = 'name is required';
//             toast.error('Name is required');
//         }
//         if (!mobileNo) {
//             newErrors.mobileNo = 'mobile No is required';
//             toast.error('Mobile Number is required');
//         } else if (!/^\d{10}$/.test(mobileNo)) {
//             newErrors.mobileNo = 'mobile number must be 10 digits';
//             toast.error('Mobile number must be 10 digits');
//         }
//         if (!password) {
//             newErrors.password = 'password id is required';
//             toast.error('Password is required');
//         }
//         // if (!emailID) {
//         //     newErrors.emailID = 'email id is required';
//         //     toast.error('Email Id is required');
//         // }
//         if (emailID && !emailRegex.test(emailID)) {
//             newErrors.emailID = 'Enter a valid email address';
//             toast.error('Enter a valid email address');
//         }

//         setErrors(newErrors);
//         const isValid = Object.keys(newErrors).length === 0;
//         if (isValid) {
//             // console.log('add droppoint')
//             // history.push('/otp')
//             handelRegisterAPi()
//             setTimer(30);
//         }
//     };

//     const handelRegisterAPi = async () => {
//         let data = new FormData();
//         data.append('name', userName)
//         data.append('number', mobileNo)
//         data.append('password', password)
//         data.append('email', emailID)
//         // const params = {
//         //     'seach': searchTerm
//         // }
//         try {
//             await axios.post("customer_register", data, {
//                 // params: params
//             }).then((res) => {
//                 if (res.data.success == true) {
//                     setRegisterData(res.data.data)
//                     toast.success(res.data.message)
//                     setRegisterBox(false)
//                     setShowOtpBox(true)
//                 } else {
//                     toast.error(res.data.message)
//                 }
//             })
//         }
//         catch (error) {
//             toast.error(error.data.message);
//         }
//     }
//     const handelBackRegister = () => {
//         setRegisterBox(true);
//         setShowOtpBox(false);
//     }
//     return (
//         <>
//             <div>
//                 <Header />
//                 <ToastContainer
//                     position="top-right"
//                     autoClose={5000}
//                     hideProgressBar={false}
//                     newestOnTop={false}
//                     closeOnClick
//                     rtl={false}
//                     pauseOnFocusLoss
//                     draggable
//                     pauseOnHover
//                 />
//                 <section className="loginsection py-5">

//                     <div className="container">
//                         <div className="py-5 hero--content">
//                             {registerBox &&
//                                 <div className="center-container" >
//                                     <div className="form-wrapper">
//                                         <form >
//                                             <div className="my-4">
//                                                 <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Sign Up</h5>
//                                                 <span style={{ color: "#6c2a7f", fontWeight: 500 }}  >Please fill your information below</span>
//                                             </div>
//                                             <div>
//                                                 <div className="my-4">
//                                                     <TextField
//                                                         required
//                                                         id="outlined-number"
//                                                         sx={{ minWidth: 370 }}
//                                                         size="small"
//                                                         value={userName}
//                                                         placeholder="Name"
//                                                         onChange={(e) => setUserName(e.target.value)}
//                                                     />
//                                                 </div>
//                                                 <div className="my-4">
//                                                     <OutlinedInput
//                                                         type="number"
//                                                         value={mobileNo}
//                                                         onChange={(e) => setMobileNo(e.target.value)}
//                                                         startAdornment={<InputAdornment position="start">+91</InputAdornment>}
//                                                         sx={{ minWidth: 370 }}
//                                                         size="small"
//                                                         placeholder="Mobile Number"
//                                                     />
//                                                 </div>
//                                                 <div className="my-4">
//                                                     <TextField
//                                                         required
//                                                         id="outlined-number"
//                                                         sx={{ minWidth: 370 }}
//                                                         size="small"
//                                                         value={emailID}
//                                                         placeholder="E-mail"
//                                                         onChange={(e) => setEmailID(e.target.value)}
//                                                     />
//                                                 </div>
//                                                 <div className="my-4">
//                                                     <FormControl variant="outlined">
//                                                         <OutlinedInput
//                                                             value={password}
//                                                             size="small"
//                                                             sx={{ minWidth: 370 }}
//                                                             placeholder="Password"
//                                                             id="outlined-basic"
//                                                             type={showPasswordIcon ? 'text' : 'password'}
//                                                             onChange={(e) => setPassword(e.target.value)}
//                                                             endAdornment={
//                                                                 <InputAdornment position="end">
//                                                                     <IconButton
//                                                                         aria-label="toggle password visibility"
//                                                                         onClick={handleClickPassword}
//                                                                         onMouseDown={handleMouseDownPassword}
//                                                                     >
//                                                                         {showPasswordIcon ? <VisibilityOff /> : <Visibility />}
//                                                                     </IconButton>
//                                                                 </InputAdornment>
//                                                             }
//                                                         />
//                                                     </FormControl>
//                                                 </div>
//                                                 <div className="d-flex justify-content-end">
//                                                     <Button variant="contained" onClick={handleNext}>Next</Button>
//                                                 </div>
//                                                 <div className="mt-4 d-flex justify-content-between logotext-color" >
//                                                     Already have an account?s
//                                                     <span className="text-blue font-weight-bold" style={{ cursor: "pointer" }} onClick={() => history.push('/login')}>
//                                                         Login to your accounts
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>}

//                             {/* OTP Form */}
//                             {showOtpBox &&
//                                 <div className="center-container" >
//                                     <div className="form-wrapper">
//                                         <form >
//                                             <div className="my-4">
//                                                 <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Enter OTP</h5>
//                                                 <span style={{ color: "#6c2a7f", fontWeight: 500 }}  >Please enter the OTP sent to your number</span>
//                                             </div>
//                                             <div>
//                                                 <div className="mt-4 mb-1">
//                                                     <TextField
//                                                         required
//                                                         type="number"
//                                                         id="outlined-number"
//                                                         sx={{ minWidth: 370 }}
//                                                         size="small"
//                                                         value={otp}
//                                                         placeholder="Enter otp"
//                                                         onChange={(e) => setOtp(e.target.value)}
//                                                     />
//                                                 </div>
//                                                 <div className="d-flex justify-content-end mb-4">
//                                                     <span
//                                                         style={{ color: resendEnabled ? 'blue' : "gray", fontWeight: 500, cursor: "pointer" }}
//                                                         onClick={resendEnabled ? handleResendOtp : null}>
//                                                         {resendEnabled ? 'Re-send otp' : `Re-send otp in ${timer}s`}
//                                                     </span>
//                                                 </div>
//                                                 <div className="d-flex justify-content-center gap-4">
//                                                     <Button onClick={handelBackRegister}>Back</Button>
//                                                     <Button variant="contained" onClick={handleOtp}>Register</Button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>}
//                             {/* <Otp />
//                                 <Login /> */}
//                         </div>
//                     </div>
//                 </section>
//             </div>
//         </>
//     )
// }
// export default RegisterDetail
import { useEffect, useState } from "react";
import { FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Button } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header from "../components/header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RegisterDetail = () => {
    const [mobileNo, setMobileNo] = useState('');
    const [password, setPassword] = useState('');
    const [showPasswordIcon, setShowPasswordIcon] = useState(false);
    const [errors, setErrors] = useState({});
    const [registerData, setRegisterData] = useState([]);
    const [userName, setUserName] = useState('');
    const [emailID, setEmailID] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [registerBox, setRegisterBox] = useState(true);
    const [timer, setTimer] = useState(30);
    const [resendEnabled, setResendEnabled] = useState(false);
    const history = useHistory();

    // Handle the password visibility toggle
    const handleClickPassword = () => setShowPasswordIcon((show) => !show);

    // Handle OTP validation and registration
    const handleOtp = () => {
        const newErrors = {};
        if (!otp) {
            newErrors.otp = 'please enter Otp';
            toast.error('Please enter Otp');
        }
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            console.log('OTP validated, proceed with registration');
            RegisterAPI();
        }
    };

    // Resend OTP logic and reset timer
    const handleResendOtp = () => {
        setTimer(30);
        setResendEnabled(false);
        handelRegisterAPi(); // Resend OTP logic here
    };

    // Register API call
    const RegisterAPI = async () => {
        try {
            await handelRegisterAPi();
            history.push('/login');
        } catch (error) {
            toast.error('Registration failed. Please try again.');  // Handle any errors that occur
        }
    };


    // Handle form validation and trigger OTP
    const handleNext = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!userName) {
            newErrors.userName = 'Name is required';
            toast.error('Name is required');
        }
        if (!mobileNo) {
            newErrors.mobileNo = 'Mobile Number is required';
            toast.error('Mobile Number is required');
        } else if (!/^\d{10}$/.test(mobileNo)) {
            newErrors.mobileNo = 'Mobile number must be 10 digits';
            toast.error('Mobile number must be 10 digits');
        }
        if (!password) {
            newErrors.password = 'Password is required';
            toast.error('Password is required');
        } else if (password.length < 8 || !/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
            newErrors.password = 'Enter minimum 8 char, 1 alphabet, 1 digit';
            toast.error('Enter minimum 8 char, 1 alphabet, 1 digit');
        }



        if (emailID && !emailRegex.test(emailID)) {
            newErrors.emailID = 'Enter a valid email address';
            toast.error('Enter a valid email address');
        }

        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            handelRegisterAPi();
        }
    };

    // Register API logic and start OTP process
    const handelRegisterAPi = async () => {
        let data = new FormData();
        data.append('name', userName);
        data.append('number', mobileNo);
        data.append('password', password);
        data.append('email', emailID);
        data.append('otp', otp);

        try {
            await axios.post("customer_register", data)
                .then((res) => {
                    if (res.data.success) {
                        setRegisterData(res.data.data);
                        toast.success(res.data.message);
                        setRegisterBox(false);
                        setShowOtpBox(true);
                        startOtpTimer();
                    } else {
                        toast.error(res.data.message);
                    }
                });
        } catch (error) {
            toast.error(error.data.message);
        }
    };

    // Function to start the OTP timer
    const startOtpTimer = () => {
        setTimer(30); // Reset timer to 30 seconds
        setResendEnabled(false);

        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(countdown);
                    setResendEnabled(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    // Handle going back to registration form from OTP
    const handelBackRegister = () => {
        setRegisterBox(true);
        setShowOtpBox(false);
    };

    return (
        <>
            <div>
                <Header />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <section className="loginsection py-5">
                    <div className="container">
                        <div className="py-5 hero--content">
                            {registerBox &&
                                <div className="center-container">
                                    <div className="form-wrapper">
                                        <form>
                                            <div className="my-4">
                                                <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Sign Up</h5>
                                                <span style={{ color: "#6c2a7f", fontWeight: 500 }}>Please fill your information below</span>
                                            </div>
                                            <div>
                                                <div className="my-4">
                                                    <TextField
                                                        required
                                                        id="outlined-number"
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        value={userName}
                                                        placeholder="Name"
                                                        onChange={(e) => setUserName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="my-4">
                                                    <OutlinedInput
                                                        type="number"
                                                        value={mobileNo}
                                                        onChange={(e) => setMobileNo(e.target.value)}
                                                        startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        placeholder="Mobile Number"
                                                    />
                                                </div>
                                                <div className="my-4">
                                                    <TextField
                                                        required
                                                        id="outlined-email"
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        value={emailID}
                                                        placeholder="E-mail"
                                                        onChange={(e) => setEmailID(e.target.value)}
                                                    />
                                                </div>
                                                <div className="my-4">
                                                    <FormControl variant="outlined">
                                                        <OutlinedInput
                                                            value={password}
                                                            size="small"
                                                            sx={{ minWidth: 370 }}
                                                            placeholder="Password"
                                                            id="outlined-basic"
                                                            type={showPasswordIcon ? 'text' : 'password'}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickPassword}
                                                                    >
                                                                        {showPasswordIcon ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <Button variant="contained" onClick={handleNext}>Next</Button>
                                                </div>
                                                <div className="mt-4 d-flex justify-content-between logotext-color">
                                                    Already have an account?
                                                    <span className="text-blue font-weight-bold" style={{ cursor: "pointer" }} onClick={() => history.push('/login')}>
                                                        Login to your account
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }

                            {/* OTP Form */}
                            {showOtpBox &&
                                <div className="center-container">
                                    <div className="form-wrapper">
                                        <form>
                                            <div className="my-4">
                                                <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Enter OTP</h5>
                                                <span style={{ color: "#6c2a7f", fontWeight: 500 }}>Please enter the OTP sent to your number</span>
                                            </div>
                                            <div>
                                                <div className="mt-4 mb-1">
                                                    <TextField
                                                        required
                                                        type="number"
                                                        id="outlined-number"
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        value={otp}
                                                        placeholder="Enter OTP"
                                                        onChange={(e) => setOtp(e.target.value)}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end mb-4">
                                                    <span
                                                        style={{ color: resendEnabled ? 'blue' : "gray", fontWeight: 500, cursor: "pointer" }}
                                                        onClick={resendEnabled ? handleResendOtp : null}
                                                    >
                                                        {resendEnabled ? 'Re-send OTP' : `Re-send OTP in ${timer}s`}
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-center gap-4">
                                                    <Button onClick={handelBackRegister}>Back</Button>
                                                    <Button variant="contained" onClick={handleOtp}>Register</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default RegisterDetail;
