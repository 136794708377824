import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SwiperCore from 'swiper';
import { IoChevronBack } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoChevronForwardSharp } from "react-icons/io5";
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export const PartnersSection = () => {
    const busIcon = process.env.PUBLIC_URL + 'assets/images/bus-partner-img.png';
    const [clientShoutOuts] = useState([
        { id: 1, name: 'Raghav', src: busIcon },
        { id: 4, name: 'Akshar', src: busIcon },
        { id: 5, name: 'Raghuveer', src: busIcon },
        { id: 5, name: 'Raghuveer', src: busIcon },
        { id: 5, name: 'Raghuveer', src: busIcon },
    ]);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: true,
        nextArrow: <IoChevronForwardSharp />,
        prevArrow: <IoChevronBack />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <section className="mt-10 partners--section">
            <div className="partnersslider--div">
                <div className="swiper partner_slider">
                    {/* <div className="container">
                        <div className="titlediv d-flex justify-content-between position-relative align-items-center mb-3 mb-lg-5">
                            <div className="swiper-button-prev swiper-prev swiper-btn fs-5 d-flex align-items-center justify-content-center shadow-sm rounded-5 p-3">
                               
                                <IoChevronBack />
                            </div>
                            <h4>our travel partners</h4>
                            <div className="swiper-button-next swiper-next swiper-btn fs-5 d-flex align-items-center justify-content-center shadow-sm rounded-5 p-3">
                              
                                <IoChevronForwardSharp />
                            </div>
                        </div>
                    </div> */}
                    {/* <section className="mt-100 partners--section">
                        <div className="titlediv d-flex justify-content-center position-relative align-items-center mb-3 mb-lg-5">
                            <h4>our travel partners</h4>
                        </div>
                        <Slider {...sliderSettings} className="px-4">
                            {clientShoutOuts.map((shoutOut) => (
                                <div key={shoutOut.id} className="px-4">
                                    <div  className="d-flex flex-column align-items-center justify-content-center bg-gray-200 transform hover-scale transition duration-300">
                                        <p className="text-center text-black font-weight-bold h5 lg-h4 mb-3">
                                            {shoutOut.name}
                                        </p>
                                        <img
                                            src={shoutOut.src}
                                            alt={shoutOut.name}
                                            className="mb-3"
                                            style={{ width: '50%', height: '50%' }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </section> */}
                    {/* <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="10000">
                                <div class="row" style={{ justifyContent: 'center' }}>
                                    <div class="col-3">
                                        <div class="align-items-center d-flex flex-sm-nowrap flex-wrap gap-3 gap-sm-0 justify-content-center justify-content-sm-start p-3 prtnercrd--content rounded-4">
                                            <img src={busIcon} width="90px" alt="bus icon" class="img-fluid" />
                                            <div class="bus_partner_name px-3">
                                                <h3 class="text-uppercase text-center m-0 h1 fw-bolder">Raghav</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="1000">
                                <div class="row" style={{ justifyContent: 'center' }}>
                                    <div class="col-3">
                                        <div class="align-items-center d-flex flex-sm-nowrap flex-wrap gap-3 gap-sm-0 justify-content-center justify-content-sm-start p-3 prtnercrd--content rounded-4">
                                            <img src={busIcon} width="90px" alt="bus icon" class="img-fluid" />
                                            <div class="bus_partner_name px-3">
                                                <h3 class="text-uppercase text-center m-0 h1 fw-bolder">Raghuveer</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="1000">
                                <div class="row" style={{ justifyContent: 'center' }}>
                                    <div class="col-3">
                                        <div class="align-items-center d-flex flex-sm-nowrap flex-wrap gap-3 gap-sm-0 justify-content-center justify-content-sm-start p-3 prtnercrd--content rounded-4">
                                            <img src={busIcon} width="90px" alt="bus icon" class="img-fluid" />
                                            <div class="bus_partner_name px-3">
                                                <h3 class="text-uppercase text-center m-0 h1 fw-bolder">Akshar Deep</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div> */}
                </div>
            </div>
        </section>
    );
};
