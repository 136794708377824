import React, { useEffect, useState } from 'react';

import { FaUser } from 'react-icons/fa';
import { IoMdWarning } from "react-icons/io";
import Header from '../components/header';
import Footer from '../components/footer';
import { Button, TextField } from '@mui/material';
import { PiPhoneCallFill } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const PassengerDetails = () => {
    const location = useLocation();
    const [isChecked, setIsChecked] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const history = useHistory()
    const {
        selectedUpperSeats,
        selectedLowerSeats,
        totalPrice,
        selectedUpperSeatPrice,
        selectedLowerSeatPrice,
        bus_id,
        bus_name,
        boarding_point_name,
        droping_point_name,
        droping_time,
        boarding_time,
        droping_date,
        boarding_date,
        selectedboadingValue,
        selecteddropingValue,
        bus_ac,
        busIcon,
        time_different,
        formattedDate,
        to,
        from,
        main_boarding_point_id,
        main_droping_point_id,
        timerStart
    } = location.state || {
        selectedUpperSeats: [],
        selectedLowerSeats: [],
        totalPrice: 0,
        selectedUpperSeatPrice: [],
        selectedLowerSeatPrice: [],
        bus_id: '',
        bus_name: '',
        boarding_point_name: '',
        droping_point_name: '',
        droping_time: '',
        boarding_time: '',
        droping_date: '',
        boarding_date: '',
        selectedboadingValue,
        selecteddropingValue,
        busIcon,
        bus_ac: '',
        time_different,
        formattedDate,
        to,
        from,
        timerStart,
        main_boarding_point_id: '',
        main_droping_point_id: '',
    };
    const totalSeats = selectedUpperSeats.length + selectedLowerSeats.length;
    const selectedTotalSeat = selectedUpperSeats.concat(selectedLowerSeats)
    const selectedTotalSeatPrice = selectedUpperSeatPrice.concat(selectedLowerSeatPrice)
    const [passengerData, setPassengerData] = useState(
        Array.from({ length: totalSeats }, () => ({
            name: '',
            age: '',
            gender: '',
        })));
    const [emailId, setEmailId] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handlePopState = () => {
            history.push('/bus-list', { selectedUpperSeats, selectedLowerSeats, totalPrice });
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [history, selectedUpperSeats, selectedLowerSeats, totalPrice]);

    const handleBack = () => {
        history.push('/bus-list', { selectedUpperSeats, selectedLowerSeats, totalPrice });
    };

    // Handle changes for text fields
    const handleTextChange = (index, field, value) => {
        setPassengerData(prevData =>
            prevData.map((passenger, i) =>
                i === index ? { ...passenger, [field]: value } : passenger
            )
        );
        if (index === 0 && value) {
            setIsCheckboxChecked(true);

        }
    };

    // Handle changes for radio buttons
    const handleGenderChange = (index, value) => {
        setPassengerData(prevData =>
            prevData.map((passenger, i) =>
                i === index ? { ...passenger, gender: value } : passenger
            )
        );
    };

    const [timer, setTimer] = useState(600); // 600 seconds = 10 minutes

    useEffect(() => {
        const interval = setInterval(() => {
            const elapsedTime = Math.floor((Date.now() - timerStart) / 1000);
            const remainingTime = 600 - elapsedTime;

            if (remainingTime <= 0) {
                clearInterval(interval);
                toast.error('Time expired. Please try again.');
                setTimer(0);
            } else {
                setTimer(remainingTime);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timerStart]);

    // Handler to toggle the checkbox state
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsCheckboxChecked(checked);

        if (checked) {
            // Copy details from the first passenger to all others
            const firstPassenger = passengerData[0];
            const updatedPassengerData = passengerData.map((data, index) =>
                index !== 0 ? { ...data, ...firstPassenger } : data
            );
            setPassengerData(updatedPassengerData);
        } else {
            // Clear details from all passengers except the first
            const updatedPassengerData = passengerData.map((data, index) =>
                index !== 0 ? { ...data, name: '', age: '', gender: '' } : data
            );
            setPassengerData(updatedPassengerData);
        }
    };

    const handleDetails = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        passengerData.forEach((passenger, index) => {
            if (!passenger.name) {
                newErrors[`name-${index}`] = `Name is required for Passenger ${index + 1}`;
                toast.error(`Name is required for Passenger ${index + 1}`);
            } else if (!passenger.age) {
                newErrors[`age-${index}`] = `Age is required for Passenger ${index + 1}`;
                toast.error(`Age is required for Passenger ${index + 1}`);
            } else if (!/^\d+$/.test(passenger.age) || passenger.age <= 0) {
                newErrors[`age-${index}`] = `Enter a valid age for Passenger ${index + 1}`;
                toast.error(`Enter a valid age for Passenger ${index + 1}`);
            } else if (!passenger.gender) {
                newErrors[`gender-${index}`] = `Gender is required for Passenger ${index + 1}`;
                toast.error(`Gender is required for Passenger ${index + 1}`);
            }
        });
        if (!name) {
            newErrors.name = 'Name is required';
            toast.error('Name is required');
        }
        if (!emailId) {
            newErrors.emailId = 'email address is required';
            toast.error('Email address is required');
        } else if (!emailRegex.test(emailId)) {
            newErrors.emailId = 'Enter a valid email address';
            toast.error('Enter a valid email address');
        } else if (!mobileNo) {
            newErrors.mobileNo = 'Mobile No is required';
            toast.error('Mobile No is required');
        } else if (!/^\d{10}$/.test(mobileNo)) {
            newErrors.mobileNo = 'Mobile number must be 10 digits';
            toast.error('Mobile number must be 10 digits');
        }

        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            console.log('add passanger', passengerData)
            console.log('add passanger', name)
            console.log('add passanger', mobileNo)
            console.log('add passanger', emailId)
            // history.push('/ticket-view')
            history.push({
                pathname: '/passnger-detail-view',
                state: {
                    passengerData, name, mobileNo, emailId, selectedTotalSeat, totalPrice, selectedTotalSeatPrice, bus_id,
                    bus_name,
                    boarding_point_name,
                    droping_point_name,
                    selectedboadingValue,
                    selecteddropingValue,
                    bus_ac,
                    droping_time,
                    boarding_time,
                    droping_date,
                    boarding_date,
                    busIcon,
                    time_different,
                    formattedDate,
                    to,
                    from,
                    remainingTime: timer,
                    main_boarding_point_id,
                    main_droping_point_id
                }
            })
        }
    };

    return (
        <>
            <Header />
            <main>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="pasenger-det--page">
                    <div className="container">
                        <div className="passengerpage--div mt-5">
                            <div className="passanger--infodiv mt-5">
                                <div className="d-flex gap-3 align-items-center">
                                    <div className="usericonpsnger d-flex align-items-center shadow-cust justify-content-center">
                                        <PiPhoneCallFill className="text-blue fs-4" />
                                    </div>
                                    <h5 className="fw-semibold text-gray">Contact Information</h5>
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <IoMdWarning size={20} color='red' />
                                <p className="fs-16 fw-medium text-danger mt-3 d-flex align-items-center gap-1 ">
                                    Your ticket will be sent to this number
                                </p>
                            </div>
                            <div className="passengerticktcard  rounded-4 ">
                                <div className="psngrdet--1 ">
                                    <div className="row">
                                        <div className="col-md-6" style={{ width: '33.33%' }}>
                                            <div className="mb-3 form-group">
                                                <label htmlFor="name" className="form-label fs-6 fw-semibold text-capitalize">
                                                    Name
                                                </label>
                                                <TextField className="form-control" size='small'
                                                    placeholder='Name'
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 " style={{ width: '33.33%' }}>
                                            <div className="mb-3 form-group">
                                                <label htmlFor="name" className="form-label fs-6 fw-semibold text-capitalize">
                                                    Email ID
                                                </label>
                                                <TextField className="form-control" size='small'
                                                    placeholder='Email ID'
                                                    value={emailId}
                                                    onChange={(e) => setEmailId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{ width: '33.33%' }}>
                                            <div className="mb-3 form-group">
                                                <label htmlFor="mobile" className="form-label fs-6 fw-semibold text-capitalize">
                                                    Phone
                                                </label>
                                                <TextField className="form-control" size='small' placeholder='Phone'
                                                    type='number'
                                                    inputProps={{ maxLength: 10 }}
                                                    value={mobileNo}
                                                    onChange={(e) => setMobileNo(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="passengerpgtitlediv">
                                <div className="titlediv">
                                    <h4 className="text-capitalize text-start fs-4 fw-bold">Passenger Details</h4>
                                </div>
                            </div> */}
                            <div className="passanger--infodiv mt-2">
                                <div className="psngerinfotitle d-flex align-items-center justify-content-between">
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="usericonpsnger d-flex align-items-center shadow-cust justify-content-center">
                                            <FaUser className="text-blue fs-4" />
                                        </div>
                                        <h5 className="fw-semibold text-gray">Passenger Information</h5>
                                    </div>
                                    <div>
                                        {totalSeats > 1 && (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className='fw-semibold'
                                                    onChange={handleCheckboxChange}
                                                />
                                                <span className="fw-semibold text-gray">

                                                {isCheckboxChecked
                                                    ? "Uncheck to clear other passenger's details"
                                                    : "Check to copy details to other passenger's"}
                                                </span>
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="pasngr--det--collectdiv mt-3">
                                    <form className="pasngerdet--colectform">
                                        {Array.from({ length: totalSeats }, (_, index) => (
                                            <div key={index} className="passengerticktcard bg-theme-opacity p-5 rounded-4 border-dashed-theme mb-4">
                                                <div className="numberpass--seatdet">
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-medium py-2 fs-18 text-capitalize border-right-2 pe-3">
                                                            Passenger {index + 1}
                                                        </div>
                                                        <div className="seat-numdiv d-flex gap-3 text-capitalize ps-3">
                                                            <strong>seat {selectedTotalSeat[index]}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="psngrdet--1 mt-4">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3 form-group">
                                                                <label htmlFor={`name-${index}`} className="form-label fs-6 fw-semibold text-capitalize">
                                                                    Name
                                                                </label>
                                                                <TextField
                                                                    className="form-control"
                                                                    size='small'
                                                                    placeholder='Name'
                                                                    id={`name-${index}`}
                                                                    value={passengerData[index]?.name || ''}
                                                                    onChange={(e) => handleTextChange(index, 'name', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3 form-group">
                                                                <label htmlFor={`age-${index}`} className="form-label fs-6 fw-semibold text-capitalize">
                                                                    Age
                                                                </label>
                                                                <TextField
                                                                    className="form-control"
                                                                    size='small'
                                                                    placeholder='Age'
                                                                    type='number'
                                                                    id={`age-${index}`}
                                                                    value={passengerData[index]?.age || ''}
                                                                    onChange={(e) => handleTextChange(index, 'age', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="selectgemnddiv">
                                                    <div className="titlediv">
                                                        <h6 className="fw-semibold fs-6 text-capitalize">Select Gender</h6>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-5 mt-4">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name={`gender-${index}`}
                                                                id={`MALE-${index}`}
                                                                checked={passengerData[index]?.gender === 'MALE'}
                                                                onChange={() => handleGenderChange(index, 'MALE')}
                                                            />
                                                            <label className="form-check-label fs-16 fw-medium text-capitalize text-gray" htmlFor={`MALE-${index}`}>
                                                                Male
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name={`gender-${index}`}
                                                                id={`FEMALE-${index}`}
                                                                checked={passengerData[index]?.gender === 'FEMALE'}
                                                                onChange={() => handleGenderChange(index, 'FEMALE')}
                                                            />
                                                            <label className="form-check-label fs-16 fw-medium text-capitalize text-gray" htmlFor={`FEMALE-${index}`}>
                                                                Female
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>

                            <div className="cnfirmsdvi mt-3">
                                <div className="warningmsg mb-4">
                                    {/* <p className="m-0 fw-medium">
                                        By Clicking On Proceed, I Agree That I Have Read And Understood The{' '}
                                        <a href="#" className="fw-bolder text-black">
                                            TnCs
                                        </a>{' '}
                                        And The{' '}
                                        <a href="#" className="fw-bolder text-black">
                                            Privacy Policy
                                        </a>
                                    </p> */}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="flx-item">
                                        <h3 className="total-amount fw-bolder mb-0">Total Amount : INR {totalPrice}</h3>
                                    </div>
                                    <div className="flx-item text-end">
                                        <Button variant="contained" onClick={handleDetails}>Proceed To Book</Button>
                                        {/* <button onClick={handleBack}>Back to Bus List</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default PassengerDetails;
