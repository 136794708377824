import React from 'react';
import './App.css';
import Home from './pages/home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import BusList from './pages/busList';
import PassengerDetails from './pages/passanger';
import TicketConfirmation from './pages/confim_ticket';
import ProfileDetails from './pages/profile';
import RegisterDetail from './pages/register';
import LoginDetail from './pages/login';
import ForgotPasswordDetail from './pages/forgot-password';
import AboutUs from './pages/aboutUs';
import PassengerView from './pages/passangerView';
import ContactUs from './pages/contact';
import TicketsDetailsView from './pages/tickets-details';
import BlogPage from './pages/blog-page';
import SingleBlog from './pages/singleBlog';
import PreviousBooking from './components/profile/previosbooking';
import CancelTicket from './components/profile/cancel-ticket';
import ShowMyTicket from './components/profile/show-my-ticket';
import ShippingPolicy from './components/profile/shipping-policy';
import TermsConditions from './components/profile/terms-and-conditions';
import CancelPolicy from './components/profile/cancel-policy';
import PrivacyPolicy from './components/profile/privacy-policy';
import Faq from './components/profile/faq';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/bus-list'>
            <BusList />
          </Route>
          <Route path='/passange-details'>
            <PassengerDetails />
          </Route>
          <Route path='/ticket-view'>
            <TicketConfirmation />
          </Route>
          {/* {localStorage.getItem('UserID') ?
            <Route path='/profile/edit-profile'>
              <ProfileDetails />
            </Route> :
            <Route path='/profile/support'>
              <ProfileDetails />
            </Route>
          } */}

          <Route path="/profile" component={ProfileDetails} />
          <Route path="/profile/edit-profile" component={ProfileDetails} />
          <Route path="/profile/support" component={ProfileDetails} />
          <Route path="/profile/previous-booking" component={ProfileDetails} />
          <Route path="/profile/cancel-ticket" component={ProfileDetails} />
          <Route path="/profile/show-my-ticket" component={ProfileDetails} />
          <Route path="/profile/shipping-delivery" component={ProfileDetails} />
          <Route path="/profile/terms-conditions" component={ProfileDetails} />
          <Route path="/profile/cancel-refund-policy" component={ProfileDetails} />
          <Route path="/profile/privacy-policy" component={ProfileDetails} />
          <Route path="/profile/faq" component={ProfileDetails} />
          <Route path='/sign-up'>
            <RegisterDetail />
          </Route>
          <Route path='/login'>
            <LoginDetail />
          </Route>
          <Route path='/forgot-password'>
            <ForgotPasswordDetail />
          </Route>
          <Route path='/about-us'>
            <AboutUs />
          </Route>
          <Route path='/passnger-detail-view'>
            <PassengerView />
          </Route>
          <Route path='/contact-us'>
            <ContactUs />
          </Route>
          <Route path='/ticket-details-view/:type/:id'>
            <TicketsDetailsView />
          </Route>
          <Route path='/blog-details'>
            <BlogPage />
          </Route>
          <Route path='/single-blog/:id'>
            <SingleBlog />
          </Route>
          {/* <Route path='/support'>
            <Supports />
          </Route>
          <Route path='/terms-conditions'>
            <TermsConditions />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/cancel-policy'>
            <CancelPolicy />
          </Route> */}
          {/* <Route path='/shipping-refund-policy'>
            <ShippingPolicy />
          </Route> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
