import { Button } from "@mui/material";
import Header from "../components/header"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { RiDiscountPercentFill } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { LuClock2 } from "react-icons/lu";
import { PiWalletBold } from "react-icons/pi";
import Footer from "../components/footer";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PassengerView = () => {
    const busIconCoupon = process.env.PUBLIC_URL + 'assets/images/bus icon coupon.png';
    const location = useLocation();
    const [tax, setTax] = useState();
    const [finalAmount, setFinalAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [couponData, setCouponData] = useState([])
    const [openPopupBoxConfirm, setOpenPopUpBoxConfirm] = useState(false)
    const [withtaxAmt, setWithTaxAmt] = useState(0);
    const [errors, setErrors] = useState({});
    const [couponOpen, setCouponOpen] = useState(false)
    const [paymentGatewayKey, setPaymentGatewayKey] = useState("");
    const { passengerData, name, emailId, mobileNo, selectedTotalSeat, totalPrice, selectedTotalSeatPrice, bus_id,
        bus_name,
        boarding_point_name,
        droping_point_name, selectedboadingValue,
        selecteddropingValue, bus_ac, droping_time,
        boarding_time,
        droping_date,
        boarding_date, busIcon, time_different, formattedDate,
        main_boarding_point_id,
        main_droping_point_id,
        to,
        from, timerStart, remainingTime } = location.state || {
            passengerData: [], name: '', emailId: '', mobileNo: '', selectedTotalSeat: [], totalPrice: 0, selectedTotalSeatPrice: [], bus_id,
            bus_name,
            boarding_point_name,
            droping_point_name,
            selectedboadingValue,
            selecteddropingValue,
            bus_ac,
            droping_time,
            boarding_time,
            droping_date,
            boarding_date,
            busIcon,
            time_different,
            formattedDate,
            to,
            from,
            remainingTime,
            main_boarding_point_id,
            main_droping_point_id
        };

    const [timer, setTimer] = useState(remainingTime || 0);
    const history = useHistory();
    const handleShowCoupon = () => {
        setCouponOpen(true)
    }
    const handleHideCoupon = () => {
        setCouponOpen(false)
    }
    useEffect(() => {
        couponList(bus_id)
        // const taxAmount = totalPrice * (discount / 100);
        const x = totalPrice - discount;
        setFinalAmount(x);
    }, [finalAmount, bus_id])


    const loadRazorpay = async () => {
        try {
            const res = await axios.get("payment_gateway");

            if (res.data.success) {
                const key = res.data.data.razorpay_key;
                // const key = "rzp_live_slbix2yn1PuFvO,ADsMFmHzrTkJVoZcDOoQ8mAA"
                setPaymentGatewayKey(key);

                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';

                script.onload = () => {
                    if (!key) {
                        toast.error("Payment key not available");
                        return;
                    }

                    // Step 3: Razorpay options
                    const options = {
                        key: key,  // Use the fetched key
                        amount: finalAmount * 100,  // Razorpay expects the amount in paise
                        currency: 'INR',
                        name: 'Your Business Name',
                        description: 'Product or Service Description',
                        handler: function (response) {
                            submitTicket(response.razorpay_payment_id);  // Handle payment success
                        },
                        prefill: {
                            name: passengerData.name,
                            email: passengerData.emailId,
                        },
                        theme: {
                            color: '#3399cc',
                        },
                    };

                    const rzp = new window.Razorpay(options);
                    rzp.open();
                };

                // Append the Razorpay script to the document body
                document.body.appendChild(script);
            } else {
                toast.error(res.data.message || "Failed to fetch payment key");
            }

        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to fetch payment key");
        }
    };

    // const ticketTax = async () => {
    //     let data = new FormData();
    //     // data.append('boarding_point', from?.id)
    //     // data.append('droping_point', to?.id)
    //     // data.append('date', formattedDate)
    //     const params = {
    //         'bus_id': 1
    //     }
    //     try {
    //         await axios.post("ticket_tax", data, {
    //             params: params
    //         }).then((res) => {
    //             console.log(res.data.data.tax);
    //             setTax(res.data.data.tax)
    //             const taxAmount = totalPrice * (res.data.data.tax / 100);
    //             const x = totalPrice + taxAmount;
    //             setfinalAmount(x);
    //         })
    //     }
    //     catch (error) {
    //         toast.error(error.data.message);
    //     }
    // }

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer <= 0) {
                clearInterval(interval);
                toast.error('Time expired. Please try again.');
            } else {
                setTimer(timer - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const processToPayment = () => {
        const newErrors = {};
        const userID = localStorage.getItem('UserID');

        if (!userID) {
            newErrors.userID = 'Please Login First';
            toast.error('Please Login First');
            setTimeout(() => {
                history.push({
                    pathname: '/login',
                    state: {
                        booking: 'pendingTicket',
                    }
                });
            }, 2000);
            return; // Exit the function if the user is not logged in
        }

        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;

        if (isValid) {
            submitTicket();
        }
    };

    const submitTicket = async (transactionId) => {

        let data = new FormData();
        data.append('bus_id', bus_id)
        data.append('user_id', localStorage.getItem('UserID'))
        data.append('boarding_point_id', selectedboadingValue?.boarding_id)
        data.append('droping_point_id', selecteddropingValue?.droping_id)
        data.append('main_boarding_point_id', main_boarding_point_id)
        data.append('main_droping_point_id', main_droping_point_id)
        data.append('boarding_id', from?.id)
        data.append('droping_id', to?.id)
        data.append('booking_date', formattedDate)
        data.append('contact_name', name)
        data.append('contact_email_id', emailId)
        data.append('contact_details', mobileNo)
        data.append('tax_amount', 0)
        data.append('sub_total', totalPrice)
        data.append('discount', discount)
        data.append('wallet', 0)
        data.append('final_price', finalAmount)
        data.append('payment_method', '1')
        data.append('transaction_id', transactionId)

        for (let i = 0; i < selectedTotalSeat.length; i++) {
            data.append(`seat_number[${i}]`, selectedTotalSeat[i]);
        }

        for (let i = 0; i < selectedTotalSeatPrice.length; i++) {
            data.append(`seat_price[${i}]`, selectedTotalSeatPrice[i]);
        }

        for (let i = 0; i < passengerData.length; i++) {
            data.append(`name[${i}]`, passengerData[i]?.name);
            data.append(`age[${i}]`, passengerData[i]?.age);
            data.append(`gender[${i}]`, passengerData[i]?.gender);
        }
        try {
            await axios.post("add_ticket", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    toast.success(res.data.message);
                    setOpenPopUpBoxConfirm(true)
                    // history.push('/ticket-view')
                } else {
                    toast.error(res.data.message || 'Invalid Message');
                    console.log(from);
                }
            })
        }
        catch (res) {
            toast.error(res.data.message);
        }
    }
    const resetAddDialogConfirm = () => {
        setOpenPopUpBoxConfirm(false)
    }
    const couponList = async (bus_id) => {
        let data = new FormData();
        data.append('bus_id', bus_id)
        const params = {
            bus_id: bus_id,
        }
        try {
            await axios.post("coupon_list", data, {
                params: params
            }).then((res) => {
                console.log(res.data.data);
                setCouponData(res.data.data)
            })
        }
        catch (error) {
            toast.error(error.data.message);
        }
    }

    const handleApplyOffer = (dis, amount) => {
        if (amount <= totalPrice) {
            setDiscount(totalPrice * (dis / 100));
            const taxAmount = totalPrice * (dis / 100);
            const x = totalPrice - taxAmount;
            setFinalAmount(x);
            handleHideCoupon();
            toast.success('Apply Coupon successfully.')
        } else {
            setDiscount(0)
            const x = totalPrice - 0;
            setFinalAmount(x);
            toast.error(`can not Apply this Coupon.`)
        }
    }

    return (
        <>
            <div>
                <Header />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="container mt-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <h1>Payment & Other Details</h1>
                        <h4 className="text-danger">Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</h4>

                    </div>
                    <div className="container">
                        <div className="row gap-2">
                            <div className="d-flex gap-5 flex-wrap">
                                <div className="col-6">
                                    {/* {/ Bus Details /} */}
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="d-flex justify-content-between align-items-center busnmflex">
                                                <div className="busname--icons">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <img src={busIcon} alt="" style={{ height: '60px', width: '60px', borderRadius: '100%', border: '1px solid black' }} className="img-fluid" />
                                                        <div>
                                                            <h5 className="m-0 me-2 fw-semibold">{bus_name}</h5>
                                                            <p className="m-0 me-2 fw-semibold">{bus_ac} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4 className="fw-medium m-0 tcktpricediv" style={{ color: 'rgb(108 42 127)' }}>{`₹ ${totalPrice}`}</h4>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row col-12 align-items-center">
                                                    <div>
                                                        <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                            <div className="bustimediv">
                                                                <h6 className="fw-semibold mb-1">{boarding_point_name}</h6>
                                                                <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>{boarding_time}</p>
                                                                <p className="fw-medium m-0">{boarding_date}</p>
                                                            </div>
                                                            <div className="bustimediv flxbasissdiv">
                                                                <span className="border--span"></span>
                                                                <p className="bustotalhours fw-medium m-0">{time_different}</p>
                                                            </div>
                                                            <div className="bustimediv">
                                                                <h6 className="fw-semibold mb-1">{droping_point_name}</h6>
                                                                <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>{droping_time}</p>
                                                                <p className="fw-medium m-0">{droping_date}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {/ Contact Details /} */}
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="d-flex justify-content-between align-items-center busnmflex">
                                                <h5 className="m-0 me-2 fw-semibold">Contact Details</h5>
                                            </div>
                                            <div className="row col-12 align-items-center">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Name</h6>
                                                            <h6 className="fw-medium mb-2" style={{ color: 'gray' }}>Email</h6>
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Phone No</h6>
                                                        </div>
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2">{name}</h6>
                                                            <h6 className="fw-semibold mb-2">{emailId}</h6>
                                                            <h6 className="fw-semibold mb-2">{mobileNo}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {/ Passengers Details /} */}
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="d-flex justify-content-between align-items-center busnmflex">
                                                <h5 className="m-0 me-2 fw-semibold">Passengers</h5>
                                            </div>
                                            <div className="row col-12 align-items-center">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Name</h6>
                                                            {passengerData.map((passenger, index) =>
                                                                <h6 className="fw-medium mb-2" key={index}>{`${passenger.name} (${passenger.gender})`}</h6>
                                                            )}
                                                        </div>
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Age</h6>
                                                            {passengerData.map((passenger, index) =>
                                                                <h6 className="fw-medium mb-2" key={index}>{`${passenger.age}`}</h6>
                                                            )}
                                                        </div>
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Seat No</h6>
                                                            {passengerData.map((passenger, index) =>
                                                                <h6 className="fw-medium mb-2" key={index}>{selectedTotalSeat[index]}</h6>
                                                            )}
                                                        </div>
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" style={{ color: 'gray' }}>Seat Price</h6>
                                                            {passengerData.map((passenger, index) =>
                                                                <h6 className="fw-medium mb-2" key={index}>{selectedTotalSeatPrice[index]}</h6>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="row col-12 align-items-center">
                                                {couponOpen == false ?
                                                    <a onClick={handleShowCoupon} style={{ minHeight: "10px" }} >
                                                        <div className="bustimediv d-flex  gap-2" style={{ cursor: "pointer" }}>
                                                            <span className="mb-2"><RiDiscountPercentFill className="text-warning fs-3" /></span>
                                                            <h5> Apply Coupon</h5>
                                                            <MdOutlineKeyboardArrowDown className="fs-3" />
                                                        </div>
                                                    </a> :
                                                    <a onClick={handleHideCoupon} >
                                                        <div className="bustimediv d-flex  gap-2" style={{ cursor: "pointer" }}>
                                                            <span className="mb-2"><RiDiscountPercentFill className="text-warning fs-3" /></span>
                                                            <h5> Apply Coupon</h5> <MdOutlineKeyboardArrowUp className="fs-3" />
                                                        </div>
                                                    </a>
                                                }
                                                {
                                                    couponOpen == true && (
                                                        <div className="">
                                                            {
                                                                couponData?.coupon?.map((coupon, index) => (
                                                                    <div>

                                                                        <div className="bustimediv d-flex gap-3 my-2 w-100 ">
                                                                            <img src={busIconCoupon} alt="" style={{ height: '48px', width: '48px', background: "#f1ecec", borderRadius: "100%", padding: "9px" }} className="img-fluid" />
                                                                            <div className="flex-grow-1">
                                                                                {/* <strong style={{ fontSize: "1rem" }}>{coupon.c_discount}%</strong> */}
                                                                                <h6>{coupon.c_name}</h6>
                                                                                <div className="d-flex gap-6">
                                                                                    <span className="col-6 col-md-4">
                                                                                        <div className="d-flex gap-2">
                                                                                            <LuClock2 />
                                                                                            <h6 style={{ fontSize: "0.75rem" }}>Valid Until</h6>
                                                                                        </div>
                                                                                        <span style={{ fontSize: "0.75rem" }}>{coupon.c_exp_date}</span>
                                                                                    </span>
                                                                                    <span className="col-6 col-md-4">
                                                                                        <div className="d-flex gap-2">
                                                                                            <PiWalletBold />
                                                                                            <h6 style={{ fontSize: "0.75rem" }}>Min Amount</h6>
                                                                                        </div>
                                                                                        <span style={{ fontSize: "0.75rem" }}>₹{coupon.min_total_amount}</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-auto d-flex align-items-center">
                                                                                <Button variant="contained" onClick={() => handleApplyOffer(coupon.c_discount, coupon.min_total_amount)}>
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="d-flex justify-content-between align-items-center busnmflex">
                                                <h5 className="m-0 me-2 fw-semibold">Price Details</h5>
                                            </div>
                                            <div className="row col-12 align-items-center">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2" >Price</h6>
                                                            {/* {/ <h6 className="fw-medium mb-2" >Tax</h6> /} */}
                                                            <h6 className="fw-semibold mb-2" >Discount</h6>
                                                            <h6 className="fw-semibold mb-2" >Total Price</h6>
                                                        </div>
                                                        <div className="bustimediv">
                                                            <h6 className="fw-semibold mb-2">{totalPrice}</h6>
                                                            {/* {/ <h6 className="fw-semibold mb-2">{tax}%</h6> /} */}
                                                            <h6 className="fw-semibold mb-2">{discount}</h6>
                                                            <h6 className="fw-semibold mb-2">{finalAmount}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row buslist--card d-flex">
                                        <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3">
                                            <div className="row col-12 align-items-center">
                                                <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                    <div className="bustimediv">
                                                        <h5 className="fw-bold" >Total Payment : {finalAmount}</h5>
                                                    </div>
                                                    <div className="bustimediv">
                                                        <Button variant="contained" onClick={loadRazorpay}>Process</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Dialog open={openPopupBoxConfirm}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "30%",
                                maxWidth: "400px",  // Set your width here
                            },
                        },
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={resetAddDialogConfirm}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="confirmed--div text-center">
                                <img
                                    src="assets/images/confirmed.svg"
                                    alt=""
                                    width="120px"
                                    height="120px"
                                    className="img-fluid"
                                />
                                <h4 className="fw-bold mt-3">Ticket Confirmed</h4>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: "grid", justifyContent: "center" }}>
                        <Button autoFocus color="error" onClick={() => {
                            history.replace('/');
                            history.block()
                        }}>
                            Back to Home
                        </Button>
                    </DialogActions>
                    {/* <DialogActions sx={{ display: 'grid', justifyContent: 'center' }}>
                        <Button
                            autoFocus
                            color="error"
                            onClick={openRazorpayPayment}
                        >
                            Click To Payment
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </div>
        </>
    )
}
export default PassengerView